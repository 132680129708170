import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';
import { LOCALSTORAGE_SPS_WEBINAR_KEY } from '@core/config';
import { PageMetasSelectors, PageMetasActions } from '@store/page-metas';
import { Location } from '@angular/common';
import { PrivacyService } from '@services/privacy/privacy.service';
import { ActivatedRoute } from '@angular/router';
import { LinkComponent } from '@components/common/link/link.component';
import { ReactiveComponent } from '@core/components/base/reactive-component';
import { SocialLinksComponent } from '@components/common/social-links/social-links.component';
import { UrlFixedPipe, SanitizePipe } from '../../../pipes';
import { HtmlHelper } from '@lib/utils/helpers';
import { Router } from '@angular/router';
import { Footer } from '@interfaces/index';
import { HttpClient  } from '@angular/common/http';
import buildVersion from '../../../../public/version.json';
import { SpsStorageService } from '@services/storage/sps-storage.service'; 
import { RootActions } from '@store/root.actions';
@Component({
  standalone: true,
  selector: 'sps-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [SanitizePipe, LinkComponent, SocialLinksComponent, DatePipe],
})
export class FooterComponent extends ReactiveComponent implements OnInit {
  public $navConfig: Signal<Footer.Config>;
  public versionInfo: { version: string; date: Date };
  public selectedLanguage$: Observable<string>;
  public currentYear = new Date().getFullYear();
  public httpClient = inject(HttpClient);
  private privacyService = inject(PrivacyService);
  constructor(private router: Router,private route: ActivatedRoute,private location: Location) {
    super();
  }
  ngOnInit(): void {
    this.getItems();
    this.fetchItemsIfNeeded();
    this.openWebinarDialog();
    this.selectedLanguage$ = this.store.select(PageMetasSelectors.selectCurrentLanguage(true));
    this.versionInfo = { version: buildVersion.version, date: new Date(buildVersion.timestamp) };
  }

  public onClick(action: string = null): void {
    switch (action) {
      case 'showCookieDialog':
        this.showCookieDialog();
        break;
    }
  }
  public openWebinarDialog():void{
 
    let currentPath = (this.location.path());
    const entry = SpsStorageService.getEntry<{ username: string; expires: number }>(LOCALSTORAGE_SPS_WEBINAR_KEY);
    
    if(entry && entry.username){
   return;
    }

    setTimeout(()=>{
  
      this.httpClient.get('https://swisspostsolutionscms.dev.sites.dropsolid-sites.com/de/jsonapi/menu_link_content/campaign-de')
      .subscribe({
        next: (data: any) => {
        
          const includedArray =  data.included; 
          const result = includedArray.filter((included:any) => included.type === "paragraph--text_media_box");
          const attributes = result[0].attributes;
         if(!attributes.field_hide_section_pragraph){
            this.store.dispatch(RootActions.showWebinarDialog());
          }
    
     
        }, error: (err) => console.log(err)
      });

    
  
    },3000);

  }
  public showCookieDialog(): void {
    this.privacyService.showCookiebotDialog();
  }

  private getItems() {
    const cookieLink: Footer.FooterLink = {
      href: null,
      label: 'Cookie Setup',
      onClick: 'showCookieDialog',
    };

    const $navConfig = this.store.selectSignal(PageMetasSelectors.selectCurrentFooterConfig);

    this.$navConfig = computed(() => {
      const config = $navConfig();

      if (!config?.links?.length) {
        return null;
      }

      const fixedConfig = this.fixPdfUrls(config);

      return {
        ...fixedConfig,
        // Inject cookie link as 5th item
        links: config.links.reduce((links, obj, i) => (i === 4 ? [...links, cookieLink, obj] : [...links, obj]), []),
      };
    });
  }

  private fetchItemsIfNeeded(): void {
    if (this.$navConfig()?.links?.length) {
      return;
    }

    this.store.dispatch(PageMetasActions.fetchFooterNavItems());
  }

  // Temporarily fix for SPSCR-328, needs to be fixed in the CMS
  private fixPdfUrls(config: Footer.Config): Footer.Config {
    const fixed = config.sections.map(section => UrlFixedPipe.transform(section));

    if (this.isServer) {
      return { ...config, sections: fixed };
    }

    const fixedWithTarget = fixed.map(HtmlHelper.addPdfTargets);

    return { ...config, sections: fixedWithTarget };
  }
}
